import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

export const useNavigate = () => {
    const [url, setUrl] = useState('');
    const [navigateStart, setNavigateStart] = useState(false);
    const [navigateComplete, setNavigateComplete] = useState(false);
    const router = useRouter();

    useEffect(() => {
        const handleChangeStart = (url: string) => {
            const [prevPath] = window.location.pathname.split('?');
            const [nextPath] = url.split('?');

            const[hashlessPath] = prevPath.split('#');
            const[hashlessNextPath] = nextPath.split('#');

            if (hashlessPath === hashlessNextPath) return;

            setUrl(url);
            setNavigateStart(true);
            setNavigateComplete(false);
        };
        const handleChangeCompleted = () => {
            setNavigateStart(false);
            setNavigateComplete(true);
        };

        router.events.on('routeChangeStart', handleChangeStart);
        router.events.on('routeChangeComplete', handleChangeCompleted);

        return () => {
            router.events.off('routeChangeStart', handleChangeStart);
            router.events.off('routeChangeComplete', handleChangeCompleted);
        };
    }, []);

    return { navigateStart, navigateComplete, url };
};
